/*
ajax请求函数模块
返回值: promise对象(异步返回的数据是: response.data)
 */
import axios from 'axios'
// import store from '@/store'
export default function ajax (dataConfig) {
  return new Promise(function (resolve, reject) {
    // 执行异步ajax请求
    if(dataConfig.loading){
      // store.commit('loadingShowFun',true)
    }
    let promise;
    promise = axios({
      method: dataConfig.method,
      url: dataConfig.url,
      ...dataConfig.config
    });
    promise.then(function (response) {
      if(dataConfig.loading){
        // store.commit('loadingShowFun',false)
      }
      resolve(response);
    }).catch(function (error) {
      if(dataConfig.loading){
        // store.commit('loadingShowFun',false)
      }
      //失败了调用reject()
      reject(error)
    })
  })
}

