import Vue from 'vue'
import App from './App.vue'
import store from './store'
// import 'ant-design-vue/dist/antd.css'
// import echarts from 'echarts'
import 'ant-design-vue/dist/antd.less'
import './assets/css/theme.less'
import './assets/css/animation.css'
import './assets/css/global.css'
import router from './router'
import axios from 'axios'
import { message } from 'ant-design-vue'
import htmlToPdf from '@/util/html2pdf/htmlToPdf'

Vue.use(htmlToPdf, {})
Vue.config.productionTip = false

Vue.prototype.bus = new Vue()

// Vue.prototype.$echarts = echarts
Vue.prototype.$message = message
Vue.prototype.$axios = axios
//let setPercentTime
axios.defaults.headers.post['Content-Type'] = 'application/json'
// 添加请求拦截器
axios.interceptors.request.use(
    function(config) {
        // let setPercent = 0
        // setPercentTime = setInterval(()=>{
        //     if(setPercent<80){
        //          setPercent=setPercent+1
        //          store.commit('setPercent',setPercent)
        //     }
        // },10)
        store.commit('setPercent', 80)
        // config.headers['X-Requested-With'] = 'XMLHttpRequest';
        let res = localStorage.getItem('token')
        config.headers.common['Authentication'] =
            res != undefined && res != '' && res != 'undefined' ? res : ''
        return config
    },
    function(error) {
        return Promise.reject(error)
    }
)
// 添加响应拦截器
axios.interceptors.response.use(
    function(response) {
        //window.console.log(response.headers.authentication);  // 对响应数据做点什么
        //sessionStorage.setItem('token', response.headers.authentication);
        // clearInterval(setPercentTime)
        store.commit('setPercent', 100)
        setTimeout(() => {
            store.commit('setPercent', 0)
        }, 300)
        return response
    },
    function(error) {
        store.commit('setSpin', false)
        message.error('网络出错，请稍后重试')
        store.commit('setPercent', 0)

        const { returnCode, returnInfo } = error.response.data
        const codeMap = {
            ban: [401, 430], // 401登录凭证无效 430 用户已被禁用
            timeOut: [420, 403], //420超时  403没token
        }
        if (codeMap.ban.includes(returnCode)) {
            message.error(returnInfo, 4)
            router.replace({ name: 'Login' })
        } else if (codeMap.timeOut.includes(returnCode)) {
            message.error(returnInfo, 4)
            router.replace({ name: 'Login' })
        }
    }
)

message.config({
    duration: 2,
    maxCount: 1,
})

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount('#app')
