/*
路由器对象模块
 */
const Home = () => import('@/pages/PC/home.vue')
const Index = () => import('@/pages/PC/view/index.vue')
const ReasonList = () => import('@/pages/PC/view/reasonList.vue')
const Question = () => import('@/pages/PC/view/question.vue')
const Result = () => import('@/pages/PC/view/result.vue')
const MobileTerminal = () => import('@/pages/mobile/mobileTerminal.vue')
const MobileDetail = () => import('@/pages/mobile/mobileDetail.vue')
const CalculatorMain = () => import('@/pages/PC/view/calculator/index.vue')
const LegalFare = () => import('@/pages/PC/view/calculator/legalFare/legalFare.vue')
const InjuryOnTheJob = () => import('@/pages/PC/view/calculator/injuryOnTheJob/injuryOnTheJob.vue')
const Damage = () => import('@/pages/PC/view/calculator/damage/damage.vue')
const MobileBlankTemplate = () => import('@/pages/mobile/mobileBlankTemplate.vue')
const Login = () => import('@/pages/PC/view/login.vue')

import VueRouter from 'vue-router'
import Vue from 'vue'
import store from '../store'
// 声明使用插件
Vue.use(VueRouter)

const router = new VueRouter({
    // 所有路由
    routes: [
        {
            path: '/',
            redirect: '/Machine',
        },
        {
            path: '/Login',
            component: Login,
            name: 'Login',
        },
        {
            path: '/Machine',
            component: Home,
            name: 'Home',
            redirect: '/Machine',
            children: [
                {
                    path: '/',
                    component: Index,
                    name: 'Index',
                },
                {
                    path: '/Machine/ReasonList',
                    component: ReasonList,
                    name: 'ReasonList',
                },
                {
                    path: '/Machine/Question',
                    component: Question,
                    name: 'Question',
                },
                {
                    path: '/Machine/Result',
                    component: Result,
                    name: 'Result',
                },
                {
                    path: '/Machine/MobileTerminal',
                    component: MobileTerminal,
                    name: 'MobileTerminal',
                },
                {
                    path: '/Machine/CalculatorMain',
                    component: CalculatorMain,
                    name: 'CalculatorMain',
                    children: [
                        {
                            path: '/Machine/LegalFare',
                            component: LegalFare,
                            name: 'LegalFare',
                        },
                        {
                            path: '/Machine/InjuryOnTheJob',
                            component: InjuryOnTheJob,
                            name: 'InjuryOnTheJob',
                        },
                        {
                            path: '/Machine/Damage',
                            component: Damage,
                            name: 'Damage',
                        },
                        {
                            path: '/Machine/Traffic',
                            component: Damage,
                            name: 'Traffic',
                        },
                    ],
                },
            ],
        },
        {
            path: '/MobileDetail',
            component: MobileDetail,
            name: 'MobileDetail',
        },
        {
            path: '/MobileBlankTemplate',
            component: MobileBlankTemplate,
            name: 'MobileBlankTemplate',
        },
    ],
})

router.beforeEach((to, from, next) => {
    store.commit('setPercent', 0)
    if (!localStorage.getItem('token') && to.name != 'Login') {
        router.push({ name: 'Login' })
    }
    if (to.name === 'Login') {
        localStorage.removeItem('token')
    }
    if (
        to.name == 'MobileTerminal' ||
        to.name == 'MobileDetail' ||
        to.name == 'MobileBlankTemplate'
    ) {
        document.getElementsByTagName('html')[0].style.fontSize =
            (document.documentElement.clientWidth / 750) * 100 + 'px'
    } else {
        document.getElementsByTagName('html')[0].style.fontSize =
            (document.documentElement.clientWidth / 1920) * 100 + 'px'
        store.commit('useAntMethode', false)
        if (store.state.useAnt) {
            Vue.use(require('ant-design-vue'))
        }
    }
    next()
})

export default router
