// 导出页面为PDF格式
import html2canvas from "html2canvas"
import JSPDF from "jspdf"

export default {
    install (Vue) {
        Vue.prototype.CanvgPdf = function () {

        };
        Vue.prototype.ExportSavePdf = function (enterpriseName) {
            var element = document.getElementById("docs");
            let scrTop = document.documentElement || document.body;
            scrTop.scrollTop = 0;
            html2canvas(element, {
                scale:6,
                logging: false,
            }).then(function (canvas) {
                var contentWidth = canvas.width;
                var contentHeight = canvas.height;
                var imgWidth = 595.28;
                var imgHeight = 592.28/contentWidth * contentHeight;
                var scrollHeigh = imgHeight > 842 ? imgHeight:842
                var pageData = canvas.toDataURL('image/jpeg', 1.0);
                var img = new Image();
                img.src = pageData;
                img.onload = function () {
                    img.style.transform = 'scale(0.5)';
                    var pdf = new JSPDF('', 'pt', [595.28, scrollHeigh]);
                    pdf.addImage(pageData, 'JPEG', -3, 0, imgWidth, imgHeight)
                    pdf.save(enterpriseName+'.pdf');
                };
            })
        }
    }
}