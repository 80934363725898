import Vue from 'vue'
import Vuex from 'vuex'
import * as Server from '@/api/server'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        legalAdvice: null,
        smartInstruments: null,
        percent: 0,
        breadcrumb: 0,
        specific: null,
        tips: false,
        useAnt: true,
        spin: false,
    },
    mutations: {
        setPercent(state, data) {
            state.percent = data
        },
        setlegalAdvice(state) {
            state.breadcrumb = state.breadcrumb + 1
        },
        setSpecific(state, data) {
            state.specific = data
        },
        setTips(state, data) {
            window.console.log(data)
            state.tips = data
        },
        useAntMethode(state, data) {
            if (state == true) {
                this.useAnt = data
            }
        },
        setSpin(state, data) {
            state.spin = data
        },
    },
    // actions: {
    //    async getLegalAdviceData({state},callback=()=>{}){
    //        if(state.legalAdvice){return}
    //         //请求数据
    //         let data = {
    //             method: 'GET',
    //             url: 'themis-terminal-api/b/q/module/getHomePageModules/themis-owl',
    //             config: {
    //                 data: {}
    //             },
    //             loading: false
    //         };
    //         let dataSource = await Server.Axios(data);

    //         let result = dataSource.data.returnData;
    //         let resultCopy=[]
    //         result.forEach((el) => {
    //             if(el.moduleList.length>0){
    //                 el.moduleList.forEach((op) => {
    //                     op.name = el.moduleName
    //                 })
    //             }
    //             let size = 8- el.moduleList.length%8
    //             if(size<8){
    //                 for(let i =0;i<size;i++){
    //                     el.moduleList.push({})
    //                 }
    //             }
    //             resultCopy = resultCopy.concat(el.moduleList)
    //         })
    //        state.legalAdvice=resultCopy
    //        callback()
    //     },
    //     async setSmartInstruments({state},callback=()=>{}){
    //         if(state.smartInstruments)return
    //         //请求数据
    //         let data = {
    //             method: 'GET',
    //             url: 'themis-terminal-api/b/q/module/getHomePageModules/themis-deer',
    //             config: {
    //                 data: {}
    //             },
    //             loading: false
    //         };
    //         let dataSource = await Server.Axios(data);
    //         if(dataSource.data.returnCode == 500){
    //             this.$message.error('服务出错，请稍后重试');
    //             return
    //         }
    //         let result = dataSource.data.returnData;
    //         let resultCopy=[]
    //         result.forEach((el)=>{
    //             if(el.moduleList.length>0){
    //                 el.moduleList.forEach((op) => {
    //                     op.name = el.moduleName
    //                 })
    //             }
    //             let size = 8- el.moduleList.length%8
    //             if(size<8){
    //                 for(let i =0;i<size;i++){
    //                     el.moduleList.push({})
    //                 }
    //             }
    //             resultCopy = resultCopy.concat(el.moduleList)
    //         })
    //         state.smartInstruments=resultCopy
    //         callback()
    //     }
    // },
    modules: {},
})
