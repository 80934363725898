import ajax from './ajax'
// const BASE_URL = 'http://localhost:4000'
export const BASE_URL = 'api';
export const CONFIG_URL = ''
export function Axios(dataConfig){
    dataConfig.url = `${BASE_URL}${CONFIG_URL}/${dataConfig.url}`
    return ajax(dataConfig)
}






